/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.bootstrap label {
  margin-top: 21px;
  padding-right: 10px;
  text-align: right;
  width: 100%;
}
@media (max-width: 767px) {
  .bootstrap label {
    text-align: left;
  }
}
.bootstrap label .optionalLabel {
  text-transform: none;
  font-weight: 100;
  font-size: 14px;
  font-style: italic;
  padding-right: 3px;
}
.validatedInput label {
  text-transform: uppercase;
  display: inline-block;
}
.validatedInput input,
.validatedInput select,
.validatedInput textarea {
  height: 50px;
  background-color: #ccc;
  border: 2px solid #333;
  padding: 0 10px;
  border-radius: 5px;
  margin: 20px 0 5px 10px;
  position: relative;
  font-size: 13pt;
  border-color: #9158a3;
  transition: 0.15s;
}
.validatedInput input:disabled,
.validatedInput select:disabled,
.validatedInput textarea:disabled {
  border-color: #f0f0f0;
  background: #fafafa;
}
.validatedInput textarea {
  width: 100%;
  height: 112px;
  padding: 10px;
  vertical-align: middle;
}
.validatedInput input {
  width: 300px;
}
.validatedInput input:read-only {
  border-color: #f5f0e9;
  background: #fafafa;
}
.validatedInput select {
  width: 324px;
}
@media (max-width: 767px) {
  .validatedInput select {
    width: 100%;
  }
}
.validatedInput .checkbox {
  width: 36px;
  height: 36px;
  background: #eee;
  display: inline-block;
  border: 2px solid #9158a3;
  vertical-align: middle;
  border-radius: 8px;
  transition: all 0.15s;
  cursor: pointer;
}
.validatedInput .checkbox:hover {
  background: #ccc;
}
.validatedInput .checkbox .sprite {
  opacity: 0;
  transition: all 0.15s;
}
.validatedInput .checkbox.selected {
  background: #52b958;
  border-color: #52b958;
}
.validatedInput .checkbox.selected .sprite {
  opacity: 1;
}
.validatedInput.spinner > .control > .greyCircle {
  cursor: pointer;
  transition: all 0.15s;
  transform: scale(1) ;
  font-family: 'Cabin', 'Verdana';
  text-align: left;
  font-size: 24px;
  color: #9158a3;
  border: 2px solid #9158a3;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  line-height: 1;
  padding-left: 8px;
  font-weight: bold;
  display: inline-block;
}
.validatedInput.spinner > .control > .greyCircle:hover {
  transform: scale(1.2) ;
}
.validatedInput.spinner > .control > .number {
  display: inline-block;
  color: #9158a3;
  font-size: 24px;
  font-weight: bold;
  margin: 0 8px;
  width: 20px;
  text-align: center;
}
.validatedInput.radio {
  cursor: pointer;
}
.validatedInput.radio label {
  text-transform: none;
  width: 70% !important;
  display: inline-block;
  vertical-align: top;
}
.validatedInput.radio .radioButton {
  display: inline-block;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background: #f5f0e9;
  border: 2px solid #0084bb;
  vertical-align: top;
}
.validatedInput.radio .radioButton .sprite {
  display: none;
}
.validatedInput.radio .radioButton.selected .sprite {
  display: block;
}
.validatedInput select.year {
  width: 105px !important;
}
.validatedInput select.month {
  width: 145px !important;
}
.validatedInput select.day {
  width: 100px !important;
}
.validatedInput input.verified,
.validatedInput select.verified {
  border-color: #52b958;
}
.validatedInput input.error,
.validatedInput select.error {
  border-color: #f24747;
}
.validatedInput .day,
.validatedInput .year {
  margin-left: 10px !important;
}
.validatedInput .status {
  width: 32px;
  height: 32px;
  display: inline-block;
  position: relative;
  top: -45px;
  left: -5px;
  float: right;
  transition: 0.15s all;
}
.validatedInput .status.creditCard {
  position: relative;
  top: -43px;
  left: -38px;
  display: block;
  height: 0;
}
.validatedInput .status.creditCard > img {
  display: none;
}
.validatedInput .status.creditCard > img.cardType {
  display: block;
  width: auto;
  height: auto;
  margin-top: 2px;
}
.validatedInput > .suggestion {
  font-size: 16px;
}
@media (max-width: 767px) {
  .validatedInput.date input,
  .validatedInput.postcode input {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .validatedInput.date .day,
  .validatedInput.postcode .day,
  .validatedInput.date .year,
  .validatedInput.postcode .year {
    margin-left: 0;
  }
}
.validatedInput.date .status,
.validatedInput.postcode .status {
  display: none;
}
