/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
form {
  position: relative;
}
form .buttonWrapper {
  width: 100%;
  text-align: right;
  margin-left: 4px;
  margin-top: 20px;
}
form .buttonWrapper > .warning {
  margin: 10px auto 0;
}
form > .sprite {
  position: absolute;
  z-index: 1;
  left: -85px;
  bottom: -58px;
  pointer-events: none;
  display: none;
}
@media (min-width: 1200px) {
  form > .sprite {
    display: block;
  }
}
form .warning {
  max-width: 300px;
  padding: 10px;
  background: #d84343;
  border-radius: 6px;
  color: #fff;
}
form .warning > a {
  background: #fff;
  padding: 5px 15px;
  border-radius: 6px;
  border: 2px solid #0084bb;
}
form .warning.verified {
  background: #3e9744;
}
form .warning .sprite {
  float: left;
}
form .warning .text {
  padding-top: 5px;
}
